import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as amplitude from '@amplitude/analytics-browser';
import { isPlatformBrowser } from '@angular/common';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      amplitude.init(environment.amplitudeApiKey, {
        defaultTracking: {
          attribution: false,
          pageViews: true,
          sessions: true,
          formInteractions: false,
          fileDownloads: false,
        },
      });
    }
  }

  public track(eventName: string, eventProperties?: any): void {
    if (isPlatformBrowser(this.platformId)) {
      amplitude.track(eventName, eventProperties);
    }
  }
}
