<a
  [href]="link"
  class="btn-arrow"
  [target]="target"
  [class.btn-arrow--white]="color === 'white'"
  [attr.aria-label]="name | translate"
>
  <span class="btn-arrow-item btn-arrow__start">
    <span class="btn-arrow__text">
      {{ name | translate }}
    </span>

    <svg-icon src="light-arrow.svg" class="btn-arrow__icon"></svg-icon>
  </span>

  <span class="btn-arrow-item btn-arrow__end">
    <span class="btn-arrow__text">
      {{ name | translate }}
    </span>

    <svg-icon src="light-arrow.svg" class="btn-arrow__icon"></svg-icon>
  </span>
</a>
