<div class="career-item" (click)="isOpenModal()">
  <div class="career-item__picture">
    <img [src]="'https://i.ytimg.com/vi/' + data + '/sddefault.jpg'" alt="Photo" class="object-cover w-full h-full">
  </div>
  <div class="career-item__main">
    <div class="career-item__count font-bold">
      {{ index }} / {{ length }}
    </div>
    <div class="career-item__icon">
      <svg-icon src="triangle.svg"></svg-icon>
    </div>
  </div>
</div>
