<div class="story-item">
  <img src="/assets/images/photo.webp" alt="Photo">

  <div class="story-item__main">
    <div class="story-item__content max-w-[450px]">
      Why we do what we do
      If your values are not invested in the business - he has no face. We are for people and about people. In other
      words - "your call is really important to us"
    </div>
  </div>
  <div class="story-item__author">
    Oksana
  </div>
  <div class="story-item__social">
    <a href="https://www.facebook.com/" class="social-item bg-black" target="_blank" aria-label="Facebook">
      <svg-icon src="icon-facebook.svg"></svg-icon>
    </a>
    <a href="https://www.linkedin.com/" class="social-item bg-black" target="_blank" aria-label="Instagram">
      <svg-icon src="icon-linkedin.svg"></svg-icon>
    </a>
  </div>
</div>
