<div
  class="pricing-item text-black"
  [ngClass]="{
      'is-trial': data.type === 'trial',
      'is-basic': data.type === 'basic',
      'is-premium': data.type === 'premium'
  }"
>
  <div
    class="pricing-item__name text-white"
    [ngClass]="{
      'bg-main-gray': data.type === 'trial',
      'bg-tundora': data.type === 'basic',
      'bg-black': data.type === 'premium'
    }"
  >
    <small>
      {{ data.subName | translate }}
    </small>

    <p class="font-extrabold relative">
      {{ data.name | translate }}
    </p>
  </div>

  <div class="pricing-item__main">
    <div class="pricing-item__col">
      <div class="pricing-item__title">
        {{ 'pricing.advantages' | translate }}
      </div>

      <div class="pricing-item__list m-auto has-border" *ngIf="data?.advantages.length === 1; else moreList">
        <div class="pricing-one-row" *ngFor="let advantage of data?.advantages">
          <svg-icon src="check.svg" class="flex flex-none"></svg-icon>
          <b>{{ advantage | translate }}</b>
        </div>
      </div>

      <ng-template #moreList>
        <div class="pricing-item__list">
          <div
            *ngFor="let advantage of data?.advantages"
            class="pricing-row"
            [class.has-left]="data.type === 'premium'"
          >
            <svg-icon src="check.svg" class="flex flex-none"></svg-icon>
            <span
              [ngClass]="{
                  'text-center lg:text-left': data.type !== 'premium'
              }"
            >
              {{ advantage | translate }}
            </span>
          </div>
        </div>
      </ng-template>
    </div>

    <div class="pricing-item__col">
      <div class="pricing-item__title">
        {{ 'pricing.level' | translate }}
      </div>

      <div class="pricing-item__list">
        <div class="pricing-row" *ngFor="let level of data?.level">
          <svg-icon src="check.svg" class="flex flex-none"></svg-icon>
          <span class="text-center lg:text-left">{{ level | translate }}</span>
        </div>
      </div>
    </div>

    <div class="pricing-item__col">
      <div class="pricing-item__title">
        {{ 'pricing.commission' | translate }}
      </div>

      <div
        *ngIf="data?.commission.length === 1"
        class="pricing-item__list no-padding m-auto has-border"
      >
        <div class="pricing-two" *ngFor="let data of data?.commission">
          <span class="font-medium">{{ 'pricing.basic.commission.item_1' | translate }}</span>
          <span class="font-medium">{{ 'pricing.basic.commission.item_2' | translate }}</span>
        </div>
      </div>

      <div
        *ngIf="data?.commission.length === 2"
        class="pricing-item__list m-auto has-border"
      >
        <ng-container *ngFor="let data of data?.commission; let idx = index">
          <div class="pricing-only">
            <span class="font-medium">{{ data | translate }}</span>
          </div>

          <div class="item-or" *ngIf="idx !== 1">
            {{ 'btn.or' | translate }}
          </div>
        </ng-container>
      </div>

      <div
        *ngIf="data?.commission.length > 2"
        class="pricing-item__list"
      >
        <div class="pricing-item__list">
          <div class="pricing-row" *ngFor="let data of data?.commission">
            <span class="text-center">{{ data | translate }}</span>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="pricing-item__footer">
    <a href="https://calendly.com/oksanagorbunova" class="pricing-item__link" target="_blank">
      {{ 'btn.try' | translate }}
    </a>
  </div>
</div>
