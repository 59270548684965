<div class="marquee-wrapper text-black">
  <div class="marquee-wrapper__items">
    <div class="marquee-item" *ngFor="let partner of partners">
      <svg-icon src="partner-{{partner.logo}}.svg"></svg-icon>
    </div>
  </div>
  <div class="marquee-wrapper__items marquee-wrapper__items--following">
    <div class="marquee-item" *ngFor="let partner of partners">
      <svg-icon src="partner-{{partner.logo}}.svg"></svg-icon>
    </div>
  </div>
</div>
