import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from "@angular/animations";
import { DOCUMENT } from "@angular/common";

import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-footer-links',
  templateUrl: './footer-links.component.html',
  styleUrls: ['./footer-links.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({
        height: '*', visibility: 'visible', opacity: 1, filter: 'blur(0)'
      })),
      state('false', style({
        height: '0px', visibility: 'hidden', opacity: 0, filter: 'blur(10px)'
      })),
      transition('true <=> false',
        animate('404ms linear')
      )
    ])
  ]
})
export class FooterLinksComponent implements AfterViewInit {
  @Input() item: any;

  public urlPlatform = environment.urlPlatform;
  public isOpen = true;
  public maxWidth = 768;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cdRef: ChangeDetectorRef
  ) {
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event?: any): void {
    this.isOpen = event.currentTarget.innerWidth >= this.maxWidth;
  }

  ngAfterViewInit(): void {
    if (this.document.defaultView && this.document.defaultView?.innerWidth <= this.maxWidth) {
      this.isOpen = false;
      this.cdRef.detectChanges();
    }
  }

  public toggleItem(): void {
    if (this.document.defaultView && this.document.defaultView?.innerWidth <= this.maxWidth) {
      this.isOpen = !this.isOpen;
    }
  }
}
