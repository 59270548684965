<nav class="footer-navigation" [class.opened]="isOpen">
  <span class="footer-navigation__header" (click)="toggleItem()">
    <h3 class="footer-navigation__title font-extrabold">{{ item?.title | translate }}</h3>
    <svg-icon src="arrow-down.svg"></svg-icon>
  </span>

  <ul role="list" [@openClose]="isOpen">
    <li *ngFor="let link of item.links">
      <ng-container [ngSwitch]="link?.typeLink">
        <button
          *ngSwitchCase="'current'"
          class="font-normal hover-weight footer-navigation__link" [routerLink]="link?.link" [fragment]="link?.anchor"
        >
          {{ link.name | translate }}
        </button>

        <a
          *ngSwitchCase="'blog'"
          [href]="link.link"
          target="_blank"
          rel="noreferrer noopener"
          class="font-normal hover-weight footer-navigation__link"
        >
          {{ link.name | translate }}
        </a>

        <a
          *ngSwitchCase="'platform'"
          [href]="urlPlatform + link.link"
          target="_blank"
          class="font-normal hover-weight footer-navigation__link"
        >
          {{ link.name | translate }}
        </a>

        <a
          *ngSwitchCase="'site'"
          [href]="link.link"
          target="_blank"
          class="font-normal hover-weight footer-navigation__link"
        >
          {{ link.name | translate }}
        </a>
      </ng-container>
    </li>
  </ul>
</nav>
