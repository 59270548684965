import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { ItemSliderAboutComponent } from './components/item-slider-about/item-slider-about.component';
import { AdvantageItemComponent } from './components/advantage-item/advantage-item.component';
import { ModalYouTubeComponent } from './components/modal-you-tube/modal-you-tube.component';
import { PricingItemComponent } from './components/pricing-item/pricing-item.component';
import { FooterLinksComponent } from './components/footer-links/footer-links.component';
import { CareerItemComponent } from './components/career-item/career-item.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { SwiperDirectiveDirective } from "./directives/swiper-directive.directive";
import { TalentRowComponent } from './components/talent-row/talent-row.component';
import { StoryItemComponent } from './components/story-item/story-item.component';
import { BtnArrowComponent } from './components/btn-arrow/btn-arrow.component';
import { BlogItemComponent } from './components/blog-item/blog-item.component';
import { InfoItemComponent } from './components/info-item/info-item.component';
import { MarqueeComponent } from './components/marquee/marquee.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { InViewDirective } from "./directives/in-view.directive";
import { YouTubePlayerModule } from "@angular/youtube-player";

const SHARED_COMPONENTS = [
  SwiperDirectiveDirective,
  ItemSliderAboutComponent,
  AdvantageItemComponent,
  ModalYouTubeComponent,
  PricingItemComponent,
  FooterLinksComponent,
  CareerItemComponent,
  MobileMenuComponent,
  TalentRowComponent,
  StoryItemComponent,
  InfoItemComponent,
  BtnArrowComponent,
  BlogItemComponent,
  MarqueeComponent,
  InViewDirective,
  HeaderComponent,
  FooterComponent,
]

@NgModule({
  declarations: [
    ...SHARED_COMPONENTS,
  ],
  exports: [
    ...SHARED_COMPONENTS,
    AngularSvgIconModule,
    ReactiveFormsModule,
    YouTubePlayerModule,
    TranslateModule,
    NgSelectModule,
  ],
  imports: [
    AngularSvgIconModule,
    ReactiveFormsModule,
    YouTubePlayerModule,
    TranslateModule,
    NgSelectModule,
    CommonModule,
    RouterModule,
    NgOptimizedImage,
  ]
})
export class SharedModule {
}
