import { Component, inject, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { fadeOutOnLeaveAnimation } from 'angular-animations';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder } from '@angular/forms';
import { filter, Subject, takeUntil } from 'rxjs';

import { GLOBAL_SIGNAL_SERVICE } from '../../services/global-signal.service';
import { AmplitudeService } from '../../services/amplitude.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    fadeOutOnLeaveAnimation()
  ]
})
export class HeaderComponent implements OnDestroy, OnInit {
  @Input() bgHeader: any;

  public globalSignalService = inject(GLOBAL_SIGNAL_SERVICE);
  public languagesList = [
    {
      name: 'EN',
      value: 'en'
    },
    {
      name: 'UK',
      value: 'uk'
    }
  ];
  public languageSwitcher = this.fb.control(null);
  public urlPlatform = environment.urlPlatform;
  public isShowLinkCompany = false;

  private unsubscribe$ = new Subject();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    private amplitudeService: AmplitudeService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {
    router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: any) => this.isShowLinkCompany = event.url.includes('company'));
  }

  ngOnInit(): void {
    this.languageSwitcher.setValue(this.translateService.currentLang);
    this.languageSwitcher.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(language => {
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('language', language);
        }

        this.translateService.use(language);
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public isSignal() {
    this.globalSignalService.setSignal('isOpenMenu', true);
  };

  public signUp(): void {
    const event = this.isShowLinkCompany ? 'company_sign_up_click' : 'talent_sign_up_click';
    this.amplitudeService.track(event, {button_position: 'header'});
  }
}
