import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pricing-item',
  templateUrl: './pricing-item.component.html',
  styleUrls: ['./pricing-item.component.scss']
})
export class PricingItemComponent {
  @Input() data: any;

  constructor() {
  }
}
