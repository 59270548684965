<div class="talent-row text-main-gray relative" [class.active]="isActive">
  <div class="talent-row__header relative w-full gap-1.5" (click)="toggleItem()">
    <div class="talent-row__price">
      {{job?.company?.name}}
    </div>
    <div class="talent-row__name text-grey-dark">
      {{ vocabularies?.position?.terms?.[job.position]?.title }}
    </div>
    <svg-icon src="arrow-down.svg" class="text-grey-dark lg:hidden"></svg-icon>
  </div>

  <div class="talent-row__wrapper">
    <div [@openClose]="isOpen" class="lg:overflow-hidden">
      <div class="talent-row__main w-full gap-1.5 pt-8 lg:pt-0 lg:overflow-hidden">
        <div class="text-grey-dark">
          <p *ngFor="let typeOfWork of job.typeOfWork">
            {{ vocabularies?.typeOfWork?.terms?.[typeOfWork]?.title }}
          </p>
        </div>
        <div class="text-grey-dark">
          {{ job?.location?.city }}
        </div>
        <div class="lg:overflow-hidden lg:truncate">
        <span *ngFor="let item of job?.stack; last as isLast">
          {{ vocabularies?.stack?.terms[item]?.title }}<em *ngIf="!isLast">,</em>
        </span>
        </div>
      </div>

    </div>

    <div class="pt-8 lg:pt-0 flex justify-center">
      <a
        [href]="urlOnJob"
        class="btn btn--small bg-grey-dark text-white relative min-w-[170px]"
        target="_blank"
      >
        {{ 'btn.apply' | translate }}
        <svg-icon src="ellips.svg" class="hidden lg:flex text-white"></svg-icon>
      </a>
    </div>
  </div>

</div>
