import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';

import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-talent-row',
  templateUrl: './talent-row.component.html',
  styleUrls: ['./talent-row.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({
        height: '*', visibility: 'visible', opacity: 1, filter: 'blur(0)'
      })),
      state('false', style({
        height: '0px', visibility: 'hidden', opacity: 0, filter: 'blur(10px)'
      })),
      transition('true <=> false',
        animate('404ms linear')
      )
    ])
  ]
})
export class TalentRowComponent implements OnInit, AfterViewInit {
  @Input() job: any;
  @Input() vocabularies: any;

  public isActive = true;
  public isOpen = true;
  public maxWidth = 992;
  public urlPlatform = environment.urlPlatform;
  public urlOnJob = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cdRef: ChangeDetectorRef
  ) {
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event?: any): void {
    this.isOpen = event.currentTarget.innerWidth >= this.maxWidth;
  }

  ngOnInit(): void {
    this.urlOnJob = `${this.urlPlatform}/company/${this.job?.company?.slug}/jobs/${this.job?.slug}`
  }

  ngAfterViewInit(): void {
    if (this.document.defaultView && this.document.defaultView?.innerWidth <= this.maxWidth) {
      this.isOpen = false;
      this.isActive = false;
      this.cdRef.detectChanges();
    }
  }

  public toggleItem(): void {
    if (this.document.defaultView && this.document.defaultView?.innerWidth <= this.maxWidth) {
      this.isOpen = !this.isOpen;
      this.isActive = !this.isActive;
    }
  }
}
