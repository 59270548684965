<div class="info-item">
  <div class="relative flex" [class.shadow]="isShadow">
    <div class="info-item__content {{ background }}">
      <div class="info-item__picture {{circleBg}}"></div>
      <span class="xxl:whitespace-nowrap">
          {{ title | translate }}
      </span>
    </div>

    <div class="info-item__arrow hidden lg:grid {{ position }} {{ colorArrow }}">
      <svg-icon [src]="iconArrow"></svg-icon>
    </div>
  </div>

  <div
    *ngIf="description && description.length > 0"
    class="info-item__description text-center lg:text-left font-normal"
  >
    <p class="lg:max-w-[200px]">
      {{ description | translate }}
    </p>
  </div>

  <svg-icon src="filter-bg.svg"></svg-icon>
</div>
