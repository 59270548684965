export const socialLinks = [
  {
    name: 'facebook',
    link: 'https://www.facebook.com/BazaIT'
  },
  {
    name: 'instagram',
    link: 'https://instagram.com/baza.it?igshid=MzRlODBiNWFlZA=='
  },
  {
    name: 'telegram',
    link: 'https://t.me/BazaITJobs'
  },
  {
    name: 'linkedin',
    link: 'https://www.linkedin.com/company/baza-team/'
  },
  {
    name: 'youtube',
    link: 'https://www.youtube.com/@bazait/featured'
  },
]
