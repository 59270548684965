<div class="mobile-menu" [class.opened]="toggleMobileMenu">
  <div class="mobile-menu__wrapper">
    <button
      class="mobile-menu__close mobile-btn btn-opened lg:hidden"
      aria-label="Open mobile menu"
      (click)="onToggleMobileMenu()">
      <div></div>
    </button>
    <nav class="mobile-menu__list">
      <ul class="mobile-menu__nav" role="list">
        <li *ngIf="isShowLinkCompany">
          <a [href]="urlPlatform + 'sign-up/company'" rel="noreferrer noopener" target="_blank">
            {{ 'link.talents' | translate }}
          </a>
        </li>
        <li *ngIf="!isShowLinkCompany">
          <a [href]="urlPlatform + 'search/companies'" rel="noreferrer noopener" target="_blank">
            {{ 'link.companies' | translate }}
          </a>
        </li>
        <li>
          <a [href]="urlPlatform + 'search/jobs'" rel="noreferrer noopener" target="_blank">
            {{ 'link.vacancies' | translate }}
          </a>
        </li>
        <li *ngIf="isShowLinkCompany">
          <a routerLink="/company" fragment="pricing" rel="noreferrer noopener">
            {{ 'link.pricing' | translate }}
          </a>
        </li>
        <li>
          <a routerLinkActive="active" routerLink="/about-us">
            {{ 'link.about' | translate }}
          </a>
        </li>
        <li *ngIf="!isShowLinkCompany">
          <a routerLinkActive="active" routerLink="/company"
             class="hover-weight">{{ 'link.for_company' | translate }}</a>
        </li>
        <li *ngIf="isShowLinkCompany">
          <a routerLinkActive="active" routerLink="/"
             class="hover-weight">{{ 'link.for_talents' | translate }}</a>
        </li>
      </ul>
      <ul class="mobile-menu__action" role="list">
        <li>
          <a
            [href]="isShowLinkCompany ? urlPlatform + 'sign-up/company' : urlPlatform + 'sign-up/talent'"
            rel="noreferrer noopener"
          >
            {{ 'btn.sign_in' | translate }}
          </a>
        </li>
        <li>
          <a
            [href]="isShowLinkCompany ? urlPlatform + 'sign-up/company' : urlPlatform + 'sign-up/talent'"
            rel="noreferrer noopener"
          >
            {{ 'btn.sign_up' | translate }}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
