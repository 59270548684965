import {
  AfterViewInit, ChangeDetectorRef, Directive,
  ElementRef, Inject, Input, PLATFORM_ID
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[appInView]'
})
export class InViewDirective implements AfterViewInit {
  private class = '';

  @Input() set animateClass(name: string) {
    this.class = name;
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private cdRef: ChangeDetectorRef,
    private element: ElementRef
  ) {
  }

  ngAfterViewInit(): void {
    const commentEl = this.element.nativeElement;
    const isBrowser = isPlatformBrowser(this.platformId);

    if (isBrowser) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const target = entry.target;
            target.classList.add(this.class);
          }
        });
      }, { threshold: 0.6 });

      observer.observe(commentEl);
    }
  }
}
