import { Component, inject, Input } from '@angular/core';
import { GLOBAL_SIGNAL_SERVICE } from "../../services/global-signal.service";

@Component({
  selector: 'app-career-item',
  templateUrl: './career-item.component.html',
  styleUrls: ['./career-item.component.scss']
})
export class CareerItemComponent {
  @Input() data: any;
  @Input() index: any;
  @Input() length: any;

  globalSignalService = inject(GLOBAL_SIGNAL_SERVICE);

  public isOpenModal(): void {
    this.globalSignalService.setSignal('isOpenModal', { status: true, data: this.data });
  }
}
