export const footerLinks = [
  {
    title: 'footer_title.company',
    links: [
      {
        name: 'link.pricing',
        link: '/company',
        anchor: 'pricing',
        typeLink: 'current'
      },
      {
        name: 'link.policy',
        link: '/policy',
        typeLink: 'current'
      },
      {
        name: 'link.blog',
        link: 'https://blog.bazait.com',
        typeLink: 'blog'
      },
      {
        name: 'API',
        link: 'https://api.bazait.com/docs',
        typeLink: 'site'
      }
    ]
  },
  {
    title: 'footer_title.platform',
    links: [
      {
        name: 'link.companies',
        link: 'search/companies',
        typeLink: 'platform'
      },
      {
        name: 'link.vacancies',
        link: 'search/jobs',
        typeLink: 'platform'
      },
      {
        name: 'link.talents',
        link: 'sign-up/company',
        typeLink: 'platform'
      }
    ]
  },
  {
    title: 'footer_title.other',
    links: [
      {
        name: 'link.recruiting',
        link: 'https://reteam.business/',
        typeLink: 'site'
      }
    ]
  }
]
