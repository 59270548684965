import { makeStateKey, StateKey, TransferState } from '@angular/core';
import { SvgHttpLoader, SvgLoader } from 'angular-svg-icon';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

export class SvgBrowserLoader implements SvgLoader {
  constructor(
    private transferState: TransferState,
    private http: HttpClient
  ) {
  }
  getSvg(url: string): Observable<any> {
    const key: StateKey<number> = makeStateKey<number>('transfer-svg:' + url);
    const data = this.transferState.get(key, null);

    if (data) {
      return of(data);
    } else {
      return new SvgHttpLoader(this.http).getSvg(`/assets/icons/${url}`);
    }
  }
}
