import { Component, effect, inject, Inject } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { filter, Subject, takeUntil } from "rxjs";
import { DOCUMENT } from "@angular/common";

import { GLOBAL_SIGNAL_SERVICE } from "../../services/global-signal.service";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent {
  globalSignalService = inject(GLOBAL_SIGNAL_SERVICE);
  openMenu = this.globalSignalService.getSignal<boolean>('isOpenMenu');

  public toggleMobileMenu = false;
  public isShowLinkCompany = false;
  public urlPlatform = environment.urlPlatform;

  private unsubscribe$ = new Subject();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {
    router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: any) => {
        this.isShowLinkCompany = event.url === '/company';
        this.document.querySelector('html')?.classList.remove('lock-scroll');
        this.globalSignalService.setSignal('isOpenMenu', false);
      });

    effect(() => {
      this.toggleMobileMenu = this.openMenu();

      if  (this.toggleMobileMenu) {
        this.document.querySelector('html')?.classList.add('lock-scroll');
      } else {
        this.document.querySelector('html')?.classList.remove('lock-scroll');
      }
    });
  }

  public onToggleMobileMenu(): void {
    this.globalSignalService.setSignal('isOpenMenu', false);
  }
}
