<header
  [ngClass]="{
    'text-dark-gray': bgHeader === 'black',
    'text-white': bgHeader === 'white'
  }"
>
  <a routerLink="/" class="logo" aria-label="Logo">
    <svg-icon src="logo-icon.svg" class="logo__icon"></svg-icon>
    <svg-icon src="logo-text.svg" class="hidden lg:flex"></svg-icon>
  </a>

  <label [attr.for]="'language-switcher'" class="lang d-flex flex-column">
    <ng-select
      class="language-select"
      [formControl]="languageSwitcher"
      [items]="languagesList"
      labelForId="language-switcher"
      [multiple]="false"
      [clearable]="false"
      [searchable]="false"
      bindLabel="name"
      bindValue="value">
    </ng-select>
  </label>

  <nav class="hidden lg:flex justify-center">
    <ul class="menu" role="list">
      <li *ngIf="isShowLinkCompany">
        <a [href]="urlPlatform + 'sign-up/company'" class="hover-weight" rel="noreferrer noopener" target="_blank">
          {{ 'link.talents' | translate }}
        </a>
      </li>
      <li *ngIf="!isShowLinkCompany">
        <a [href]="urlPlatform + 'search/companies'" class="hover-weight" rel="noreferrer noopener" target="_blank">
          {{ 'link.companies' | translate }}
        </a>
      </li>
      <li>
        <a [href]="urlPlatform + 'search/jobs'" class="hover-weight" rel="noreferrer noopener" target="_blank">
          {{ 'link.vacancies' | translate }}
        </a>
      </li>
      <li *ngIf="isShowLinkCompany">
        <a routerLink="/company" fragment="pricing" class="hover-weight" rel="noreferrer noopener">
          {{ 'link.pricing' | translate }}
        </a>
      </li>
      <li>
        <a href="https://blog.bazait.com" class="hover-weight" rel="noreferrer noopener" target="_blank">
          {{ 'link.blog' | translate }}
        </a>
      </li>
      <li>
        <a routerLinkActive="active" routerLink="/about-us" class="hover-weight">
          {{ 'link.about' | translate }}
        </a>
      </li>
      <li *ngIf="!isShowLinkCompany">
        <a routerLinkActive="active" routerLink="/company" class="hover-weight active">
          {{ 'link.for_company' | translate }}
        </a>
      </li>
      <li *ngIf="isShowLinkCompany">
        <a routerLinkActive="active" routerLink="/" class="hover-weight">
          {{ 'link.for_talents' | translate }}
        </a>
      </li>
    </ul>
  </nav>

  <div class="action">
    <a
      [href]="urlPlatform + 'login'"
      target="_blank"
      class="btn btn__link" rel="noreferrer noopener"
      [attr.aria-label]="'btn.sign_in' | translate"
    >
      {{ 'btn.sign_in' | translate }}
    </a>
    <a
      [href]="isShowLinkCompany ? urlPlatform + 'sign-up/company' : urlPlatform + 'sign-up/talent'"
      class="btn btn--small"
      target="_blank"
      [ngClass]="{
        'text-white bg-black': bgHeader === 'black',
        'text-black bg-white': bgHeader === 'white'
      }"
      [attr.aria-label]="'btn.sign_up' | translate"
      (click)="signUp()"
    >
      {{ 'btn.sign_up' | translate }}
    </a>
  </div>

  <a
    [href]="isShowLinkCompany ? urlPlatform + 'sign-up/company' : urlPlatform + 'sign-up/talent'"
    target="_blank"
    class="mobile-btn-registration"
    [attr.aria-label]="'btn.sign_up' | translate"
  >
    {{ 'btn.sign_up' | translate }}
  </a>

  <button
    class="mobile-btn lg:hidden"
    aria-label="Open mobile menu"
    (click)="isSignal()"
  >
    <div></div>
  </button>
</header>
