import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from "ngx-translate-messageformat-compiler";
import { TranslateCompiler, TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APP_INITIALIZER, NgModule, TransferState } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule, SvgLoader } from 'angular-svg-icon';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { BrowserModule } from '@angular/platform-browser';
import {register} from 'swiper/element/bundle';

register();

import { ChangeLanguagesService } from "./shared/services/change-languages.service";
import { SvgBrowserLoader } from './shared/loaders/svg-browser.loader';
import { AmplitudeService } from './shared/services/amplitude.service';
import { AppComponent } from "./core/components/app/app.component";
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

export function svgLoaderFactory(transferState: TransferState, httpClient: HttpClient) {
  return new SvgBrowserLoader(transferState, httpClient);
}

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    TransferHttpCacheModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    AngularSvgIconModule.forRoot({
      loader: {
        deps: [TransferState, HttpClient],
        useFactory: svgLoaderFactory,
        provide: SvgLoader,
      }
    }),
    TranslateModule.forRoot({
      useDefaultLang: false,
      loader: {
        provide: TranslateLoader,
        useClass: ChangeLanguagesService,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
  ],
  providers: [
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: {
        locales: ['en', 'uk'],
      }
    },
    {
      provide: APP_INITIALIZER,
      deps: [AmplitudeService],
      useFactory: () => () => {},
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
