import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-item',
  templateUrl: './info-item.component.html',
  styleUrls: ['./info-item.component.scss']
})
export class InfoItemComponent {
  @Input() title: string = '';
  @Input() isShadow = false;
  @Input() description: string  = '';
  @Input() position?: string | '';
  @Input() circleBg?: string | 'bg-dark-gray';
  @Input() background = 'bg-pink';
  @Input() colorArrow = 'text-white';
  @Input() iconArrow = 'arrow-info.svg';
}
