import { AfterViewInit, Directive, ElementRef, Inject, Input, PLATFORM_ID } from '@angular/core';
import { SwiperContainer } from 'swiper/element/swiper-element';
import { isPlatformBrowser } from '@angular/common';
import { SwiperOptions } from 'swiper';

@Directive({
  selector: '[appSwiperDirective]',
})
export class SwiperDirectiveDirective implements AfterViewInit {
  public swiperElement: any;
  public swiperId: any;

  @Input() config?: SwiperOptions;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private el: ElementRef<SwiperContainer>
  ) {
    this.swiperElement = el.nativeElement;
    this.swiperId = this.swiperElement.getAttribute('id') || 'swiper-id';
  }

  ngAfterViewInit() {
    Object.assign(this.el.nativeElement, {
      ...this.config,
      a11y: {
        id: this.swiperId
      },
    });

    // @ts-ignore
    if (isPlatformBrowser(this.platformId)) {
      this.el.nativeElement.initialize();
    }
  }
}
