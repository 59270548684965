import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-btn-arrow',
  templateUrl: './btn-arrow.component.html',
  styleUrls: ['./btn-arrow.component.scss']
})
export class BtnArrowComponent {
  @Input() name: any;
  @Input() link: any;
  @Input() color = 'white'; // white, black
  @Input() target = '_blank';

  constructor() {
  }
}
