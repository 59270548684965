<footer class="footer">
  <div class="footer__slogan">{{ 'footer_title.slogan' | translate }}</div>

  <div class="footer__main">
    <div class="flex flex-col gap-10">
      <a routerLink="/" class="logo" aria-label="Footer Logo">
        <svg-icon src="logo-icon.svg" class="logo__icon"></svg-icon>
        <svg-icon src="logo-text.svg" class="logo__text"></svg-icon>
      </a>

      <div class="flex flex-col gap-2 text-small">
        <p class="max-w-[300px]">{{ 'footer_title.partnership_label' | translate }}</p>

        <a href="mailto:mailto:viktorianalyvaiko@bazait.com">viktorianalyvaiko@bazait.com</a>
      </div>
    </div>

    <div class="gap-[inherit] grid lg:grid-cols-3">
      <app-footer-links
        *ngFor="let links of footerLinks"
        [item]="links"
        appInView [animateClass]="'c-animate__fadeInUpBlur'"
      ></app-footer-links>
    </div>
  </div>

  <ul role="list" class="footer__social" appInView [animateClass]="'c-animate__fadeInUpBlur'">
    <li *ngFor="let social of socialLinks">
      <a [href]="social.link" target="_blank" [attr.aria-label]="social.name">
        <svg-icon [src]="'social-' + social.name + '.svg'"></svg-icon>
      </a>
    </li>
  </ul>
</footer>
