import { Component } from '@angular/core';

@Component({
  selector: 'app-marquee',
  templateUrl: './marquee.component.html',
  styleUrls: ['./marquee.component.scss']
})
export class MarqueeComponent {
  public partners = [
    {
      logo: 'jooble',
    },
    {
      logo: 'sendpulse',
    },
    {
      logo: 'roosh',
    },
    {
      logo: 'choice31',
    },
    {
      logo: 'liki24',
    },
    {
      logo: 'betterme',
    },
    {
      logo: 'genesis',
    },
    {
      logo: 'mate-academy',
    },
    {
      logo: 'geo-pay',
    },
    {
      logo: 'wix',
    },
    {
      logo: 'dtek',
    },
    {
      logo: 'giga-cloud',
    }
  ]
}
