<div class="about-item" data-cursor>
  <div class="about-item__picture">
    <img src="/assets/images/team-photos/{{data.image}}" alt="Photo" loading="lazy">
  </div>
  <div class="about-item__footer">
    <div class="about-item__name font-extrabold">
      {{ data.name | translate }}
    </div>
    <div class="about-item__position">
      {{ data.position | translate }}
    </div>
    <div class="about-item__description col-span-full font-extralight">
      {{ data.description | translate }}
    </div>
  </div>
</div>
