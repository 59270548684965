import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChangeLanguagesService implements TranslateLoader {
  constructor(
    private http: HttpClient
  ) {
  }

  getTranslation(language: string): Observable<any> {
    return this.http.get(`${environment.api}/main/translates`, {
      params: {
        project: 'landing',
        language,
      }
    });
  }
}
