<app-mobile-menu></app-mobile-menu>

<router-outlet></router-outlet>

<app-footer
  class="full-page-section container bg-dark-gray lg:justify-center"
  [footerLinks]="footerLinks"
  [socialLinks]="socialLinks"
></app-footer>

<app-modal-you-tube></app-modal-you-tube>
