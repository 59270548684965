<section class="modal" *ngIf="isShowModal">
  <div class="modal__overlay" (click)="closeModal()"></div>
  <div class="modal__wrapper">
    <div class="modal__main">
      <youtube-player
        [videoId]="videoId"
        (ready)="savePlayer($event)"
      ></youtube-player>
    </div>

    <div class="modal__close">
      <button class="text-black" (click)="closeModal()">
        <svg-icon src="close.svg"></svg-icon>
      </button>
    </div>
  </div>
</section>
