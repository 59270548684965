import { Component, effect, inject, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from "@angular/common";

import { GLOBAL_SIGNAL_SERVICE } from "../../services/global-signal.service";

let apiLoaded = false;

@Component({
  selector: 'app-modal-you-tube',
  templateUrl: './modal-you-tube.component.html',
  styleUrls: ['./modal-you-tube.component.scss']
})
export class ModalYouTubeComponent implements OnInit {
  public videoId = '';

  globalSignalService = inject(GLOBAL_SIGNAL_SERVICE);
  showModal = this.globalSignalService.getSignal<any>('isOpenModal');

  public isShowModal = false;
  private player: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
    effect(() => {
      this.videoId = this.showModal()?.data;
      this.isShowModal = this.showModal()?.status;
    });
  }

  ngOnInit(): void {
    if (!apiLoaded) {
      const tag = this.document.createElement('script');
      tag.setAttribute('rel', 'preconnect');
      tag.src = 'https://www.youtube.com/iframe_api';
      this.document.body.appendChild(tag);
      apiLoaded = true;
    }
  }

  public savePlayer(player: YT.PlayerEvent): void {
    this.player = player.target;
  }

  public closeModal(): void {
    this.globalSignalService.setSignal('isOpenModal', false);
  }
}
